<template>
  <div class="wrapper">
    <div class="resource-container">
      <div class="title">公司资源</div>
      <p class="resource-desc">
        公司拥有丰富的产研资源和强大的研发应用能力。覆盖了一支“院士、千人学者、973项目首席”领衔的优秀学术团队；联合了10多个国家重点实验室、国家级工程研究中心、技术研究中心及联合实验室；拥有实体汽车智能工厂和8大智能制造实验室等真实完备的产品研发和验证基地；具备十年以上的工艺、工装、仿真及连接技术分析专业团队；已申请受理智能制造发明专利101项、软著8项等。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Resource',
}
</script>

<style lang="less" scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
}

.resource-container {
  .g_center();
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 104px 0;
  .title {
    width: 135px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #393939;
  }
  .resource-desc {
    width: 900px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #272727;
    margin-top: 28px;
  }
}
</style>
