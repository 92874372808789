<template>
  <div class="about">
    <banner-wrapper :wrapperText="wrapperText" />
    <TopNav :list="nav" />
    <AboutUs id="vision" />
    <Vision />
    <Resource />
    <Development />
    <Join id="join" />
    <Recruit />
  </div>
</template>
<script>
import BannerWrapper from '@/components/BannerWrapper/BannerWrapper'
import TopNav from '@/components/TopNav'
import AboutUs from './components/AboutUs'
import Vision from './components/Vision'
import Resource from './components/Resource'
import Development from './components/Development'
import Join from './components/Join'
import Recruit from './components/Recruit'

export default {
  name: 'About',
  components: {
    BannerWrapper,
    TopNav,
    AboutUs,
    Vision,
    Resource,
    Development,
    Join,
    Recruit,
  },
  data () {
    return {
      wrapperText: {
        title: '关于我们',
        desc: '浙江西图盟数字科技有限公司为浙江省数字化设计与制造创新中心实体运营单位，基于核心股东单位和众多联盟机构在工程制造领域中累积的有效经验，以汽车行业为重点，以离散制造业为主要服务对象，为离散制造业的数字化改造提供定制化解决方案。',
        imgSrc: require('@/assets/about/about_banner@1x.png'),
      },
      nav: [
        {
          name: '公司介绍',
          hash: 'vision',
        },
        {
          name: '加入我们',
          hash: 'join',
        },
      ],
    }
  },
}
</script>
