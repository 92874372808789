<template>
  <div class="wrapper" :style="{ backgroundImage: `url(${wrapperText.imgSrc})` }">
    <div class="container">
      <p class="title">{{ wrapperText.title }}</p>
      <p class="description">{{ wrapperText.desc }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Banner',
  props: {
    wrapperText: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
  height: 524px;
  background-size: 1920px 524px, 100% 100%;
  background-position: 57% top;
  background-repeat: no-repeat;
}

.container {
  .g_center();
  height: 100%;
  overflow: hidden;
}

.title {
  margin: 130px 0 0 0;

  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 70px;

  color: #000000;
}

.description {
  width: 690px;
  height: 60px;
  margin: 50px 0 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
}
</style>
