<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">发展历程</p>
      <div class="developments">
        <div
          class="development"
          v-for="(development, index) in developments"
          :key="index"
        >
          <p class="development_date">{{development.date}}</p>
          <div class="point"></div>
          <p class="development_description">{{development.description}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Development',
  props: {},
  data () {
    return {
      developments: [
        {
          date: '2019.09.23',
          description: '浙江省经信厅组织召开的 制造业创新中心申报 现场答辩评审会议',
        },
        {
          date: '2019.10.29',
          description: '浙江省经信厅、财政厅联合发文（浙经信技术﹝2019﹞167号）关于公示2019年制造业创新中心创建名单',
        },
        {
          date: '2020.3.16',
          description: '浙江省政府召开“全省高质量发展大会”，会上省经信厅予以授牌',
        },
        {
          date: '2020.11.27',
          description: '浙江省数字化设计与制造创新 中心实体公司“浙江西图盟数字科技有限公司”注册成立',
        },
      ],
    }
  },
}
</script>
<style lang='less' scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
  background: #f5f5f5;
  overflow: hidden;
}

.container {
  .g_center();
}

.title {
  margin: 48px 0 60px 0;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #393939;
}

.developments {
  position: relative;
  overflow: hidden;
}

.development {
  display: inline-block;
  width: 300px;
  margin-right: 20px;
  vertical-align: top;
  margin-bottom: 50px;
}

.point {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 10px auto;
  background: rgba(151, 183, 247, 0.5);
  border-radius: 50%;

  &::before {
    .absolutely_center();
    display: block;
    width: 10px;
    height: 10px;
    background: #0057ff;
    border-radius: 50%;
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    right: -340px;
    transform: translateY(-1px);
    display: block;
    width: 340px;
    height: 3px;
    background: #ececec;
    z-index: 0;
    content: '';
  }
}

.development_date {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.development_description {
  width: 240px;
  margin: 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #3e3e3e;
}
</style>
