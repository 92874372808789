<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">关于我们</p>
      <p class="description">浙江西图盟数字科技有限公司成立于2020年11月，为浙江省数字化设计与制造创新中心实体运营公司，本公司依托于核心股东单位（国家数字化设计与制造创新中心、吉利控股集团等）和众多创新联盟机构在工程制造领域深耕累积的先进科研成果及有效解决方案，以汽车行业为重点，以离散制造业为主要服务对象，为全国制造企业的数字化改造和升级提供：自主研发（国产化） 工业软件应用及平台、智能制造工厂工程技术服务、基于未来工厂高效运营管理的数字孪生服务、由订单驱动的全流程整体物流解决方案等。</p>
      <div class="imgs">
        <div
          class="img_wrapper"
          v-for="(img, index) in imgs"
          :key="index"
        >
          <img
            class="img"
            :src="img"
            alt="img"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutUs',
  data () {
    return {
      imgs: [
        require('@/assets/about/introduce/about_introduce_icon1@1x.png'),
        require('@/assets/about/introduce/about_introduce_icon2@1x.png'),
        require('@/assets/about/introduce/about_introduce_icon3@1x.png'),
        require('@/assets/about/introduce/about_introduce_icon4@1x.png'),
      ],
    }
  },
}
</script>
<style lang='less' scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
}

.container {
  .g_center();
  height: 100%;
  margin: 62px auto 75px auto;
  overflow: hidden;
}

.title {
  margin: 0 0 24px 0;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-indent: 2em;
  text-transform: uppercase;
  color: #3e3e3e;
}

.imgs {
  display: flex;
  width: 1080px;
  margin: 97px auto 0 auto;
  justify-content: space-around;
  align-content: center;
  .img_wrapper {
    position: relative;
    height: 51px;
    .img {
      .absolutely_center();
    }
  }
}
</style>
