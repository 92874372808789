<template>
  <div class="wrapper">
    <span class="title">招聘职位</span>
    <div class="container">
      <ul class="posts-list">
        <li class="post-header">
          <div class="posts-item">
            <span>职位名称</span><span>招聘人数</span><span>工作地点</span><span>详情</span>
          </div>
        </li>
        <li class="post-detail-list" v-for="(item, index) in recruit.postsList" :key="index">
          <div
            class="posts-item"
            :class="item.expand ? 'active' : ''"
            @click="handleExpandIcon(item, index)"
          >
            <span>{{ item.name }}</span>
            <span>{{ item.num }}</span>
            <span>{{ item.address }}</span>
            <span>
              <icon
                v-if="item.expand"
                class="up"
                icon="up"
              />
              <icon
                v-else
                class="down"
                icon="down"
              />
            </span>
          </div>
          <div class="post-jd" v-show="item.expand">
            <div class="items">
              <span class="tips">岗位职责</span>
              <p v-for="(desc, idx) in item.duty" :key="idx">{{ desc }}</p>
            </div>
            <div class="items">
              <span class="tips">任职要求</span>
              <p v-for="(desc, idx) in item.request" :key="idx">{{ desc }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Recruit',
  data () {
    return {
      recruit: {
        title: '招聘职位',
        postsList: [
          {
            name: '销售经理',
            num: '1-2人',
            address: '杭州',
            duty: [
              '1、负责公司产品销售、产品的应用情况调研与反馈',
              '2、开拓市场，发展新客户, 搜寻潜在的客户群体；',
              '3、负责对工业工程服务的客户挖掘及订单达成；',
              '4、在展会中为潜在的目标客户群体介绍公司产品及了解顾客需求向公司内部开发人员进行沟通并完成销售指标；',
              '5、管理维护客户关系以及客户间的长期战略合作计划。',
            ],
            request: [
              '1、一年以上软件销售工作经验，有ERP、MES销售经验优先；',
              '2、了解软件的销售模式，有一定开发客户的经验，目标客户以国内中小型离散型制造业为主（汽车零配件、装备制造、五金件）的候选人优先；',
              '3、熟悉政府、大型工业企业运作及招投标流程；',
              '4、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧，具有亲和力；',
              '5、具备一定的市场分析及判断能力，良好的客户服务意识；',
            ],
          },
          {
            name: '市场调研专员',
            num: '2-3人',
            address: '杭州',
            duty: [
              '1、负责公司产品销售、产品的应用情况调研与反馈；',
              '2、开拓市场，发展新客户, 搜寻潜在的客户群体；',
              '3、负责对工业工程服务的客户挖掘及订单达成；',
              '4、在展会中为潜在的目标客户群体介绍公司产品及了解顾客需求向公司内部开发人员进行沟通并完成销售指标；',
              '5、管理维护客户关系以及客户间的长期战略合作计划。',
              '6、发掘外部市场资源和合作机会，为公司的产品立项和业务顺利开展提供支持。',
            ],
            request: [
              '1、一年以上软件销售工作经验，有ERP、MES销售经验优先；',
              '2、了解软件的销售模式，有一定开发客户的经验，目标客户以国内中小型离散型制造业为主（汽车零配件、装备制造、五金件）的候选人优先；',
              '3、熟悉政府、大型工业企业运作及招投标流程；',
              '4、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧，具有亲和力；',
              '5、具备一定的市场分析及判断能力，良好的客户服务意识；',
              '6、具有良好的沟通能力和人际交往意识。',
            ],
          },
        ],
      },
    }
  },
  methods: {
    handleExpandIcon (item, i) {
      if (item.expand) {
        this.$set(item, 'expand', false)
        return
      }
      this.$set(item, 'expand', true)
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
  background-image: url('~@/assets/about/recruit/about_partner_bg@2x.jpg');
  background-size: 100%;
  background-position: center;
  overflow: hidden;
  position: relative;
}

.container {
  .g_center();
  min-height: 410px;
  margin: 138px auto 104px auto;
  padding: 54px 58px 20px 58px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 9px 50px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.title {
  width: 138px;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #393939;
}

.posts-list {
  width: 100%;
  padding: 0 16px;
  li {
    width: 100%;
    min-height: 52px;
    border-bottom: 1px solid #F6F6F6;
    overflow: hidden;
    .posts-item {
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        width: 150px;
        font-size: 16px;
        line-height: 18px;
        text-transform: uppercase;
        color: #2D2D2D;
        text-align: center;
      }
      & :first-child {
        text-align: left;
      }
      & :last-child {
        text-align: right;
      }
    }
    .active {
      & > span {
        color: #064ACB;
      }
    }
    & > span {
      width: 50px;
    }
    & :last-child {
      border-bottom: none;
    }
  }
  .post-detail-list {
    & > span {
      width: 50px;
    }
    .post-jd {
      width: 100%;
      min-height: 100px;
      background: #F5F5F5;
      box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.03);
      padding: 12px 8px;
      .items {
        .tips {
          width: 142px;
          height: 34px;
          background-image: url('~@/assets/icons/tips.png');
          background-size: 100%;
          background-position: center;
          color: #F6F6F6;
          font-size: 16px;
          line-height: 26px;
          display: flex;
          justify-content: left;
          align-items: center;
          align-content: center;
          margin-top: 40px;
          margin-bottom: 24px;
          padding-left: 7px;
        }
        & > p{
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          display: flex;
          align-items: center;
          margin: 3px 8px 0;
          text-align: left;
        }
      }
    }
  }
}
</style>
