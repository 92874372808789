<template>
  <div class="wrapper">
      <h3 class="title">企业文化</h3>
    <div class="vision-container">
      <!-- <div class="vision-info">
        <span class="title">公司愿景</span>
        <p class="vision-desc">
          助力中国制造业实现数字化转型和大规模定制化转型，突破工业领域“卡脖子”技术；
          基于分布式实体工厂构建全社会统一的虚拟工厂，实现创意驱动制造的新制造模式；
          推动全产业智力资源、产能资源、供应链资源的共享平台。
        </p>
      </div>
      <div class="vision-img">
        <img src="~@/assets/about/vision/about_vision_img1@1x.png" alt="">
      </div> -->
      <div class="info" v-for="item in info" :key="item.title">
        <h5 class="sub-title">{{item.title}}</h5>
        <p v-for="(data,index) in item.detail" :key="index" v-html="data">{{data}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Vision',
  data () {
    return {
      info: [{
        title: '使命',
        detail: ['E=C<sup>2</sup>M', '用数字化手段提供连接端到端的生产服务，赋能制造业打通C2M模式。'],
      }, {
        title: '愿景',
        detail: ['致力于成为制造业C2M生态解决方案领导者。'],
      }, {
        title: '价值观',
        detail: ['为人民服务 创业者心态'],
      }],
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
  background: #f5f5f5;
  font-family: 'PingFang SC';
  line-height: 1.4;
  padding: 80px 0;
}

.title {
  margin: 0 0 24px 0;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  color: #393939;
}

.vision-container {
  .g_center();
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 9px 50px rgba(0, 0, 0, 0.05);
}

.info{
  flex: 1;
  padding: 80px 30px;
  font-size: 16px;
  color: #ABABAB;
  &:hover{
    background-image: url('~@/assets/about/join/about_join_page_bg.png');
    background-size: cover;
    background-color: #064ACB;
    // background-color: linear-gradient(180deg, #1A4DC3 35.01%, rgba(26, 77, 195, 0) 89.66%);
    color:#fff;
    .sub-title{
      color:#fff;
    }
  }
  .sub-title{
    font-size:32px;
    font-weight: 400;
    color: #3E3E3E;
    margin-bottom: 40px;
  }
}

.vision-info {
  width: 500px;
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #393939;
  }
  .vision-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #272727;
    margin-top: 28px;
  }
}

.vision-img {
  width: 394px;
  height: 294px;
  & > img {
    max-width: 100%;
  }
}
</style>
